import React, { useCallback, useState, useEffect } from "react";
import { Col, Container, Row, Nav, NavDropdown } from "react-bootstrap";
import "swiper/css/bundle";
import MatchesCard from "@/components/cards/matches";
import api from "@/axios";
import Masonry from "react-masonry-css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import Tab from "react-bootstrap/Tab";
import InfiniteScroll from "react-infinite-scroller";

import sliderTop01 from "@/images/bg/news/top.jpg";

SwiperCore.use([Autoplay, EffectFade]);

const NewsPage = () => {
  const breakpointColumnsObj = {
    default: 5,
    1600: 3,
    1000: 2,
    875: 1,
  };

  const [apiData, setData] = useState(null);
  const [uniqueCategories, setUniqueCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [limitLoadMore, setLimitLoadMore] = useState(10);
  const [LimitAll, setLimitAll] = useState(10);

  const getData = async increase => {
    try {
      const response = await api.get(`/news/o/get`, {
        params: {
          type: "post",
          limit: increase ? limitLoadMore + 20 : limitLoadMore,
          newResponse: true
        },
      });
      setLimitAll(response.data.count);

      if (increase) setLimitLoadMore(limitLoadMore + 20);

      const resCatsVisible = await api.get(`/settings/homepage`);
      const catsVisible = resCatsVisible.data.find(
        v => v.name == "news_categories"
      );
      const catsArr = Object.values(catsVisible.value.categories);

      const newsFinal = response.data.data.filter(x =>
        catsArr.map(v => x.categories.includes(v))
      );

      setData(newsFinal);

      const uniqueCategories = [];
      response.data.data.filter(v => {
        let catsSep = v.categories.split(",");
        catsSep.map(cat => {
          const isDuplicate = uniqueCategories.includes(cat);
          if (!isDuplicate) {
            uniqueCategories.push(cat);
            return true;
          }
          return false;
        });
      });
      setUniqueCategories(catsVisible.value.categories);

      setError(null);
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(false);
  }, []);

  const mainSlideOptions = {
    slidesPerView: 1,
    loop: false,
    effect: "fade",
  };

  const [selectedCategoryData, setSelectedCategoryData] = useState(apiData);
  function handleSelect(e) {
    setSelectedCategoryData(apiData.filter(v => v.categories.includes(e)));
  }

  const loadMoreData = e => {
    getData(true);
  };

  return (
    <>
      <section className="main-slider slider-subpages slider-subpages-manual">
        <Swiper {...mainSlideOptions}>
          <SwiperSlide>
            <div
              className="image-layer"
              style={{
                backgroundImage: `url('${sliderTop01}')`,
              }}
            ></div>
            <Container className="w-auto animated fadeInUp">
              <Row>
                <Col lg={12} className="text-center">
                  <h3 className="main-slider__title">AKTUALNOŚCI</h3>
                  {/* <h1 className="text-white fw-bolder">AKTUALNOŚCI</h1> */}
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
        </Swiper>
      </section>
      <section className="commonSection news-page-tabs-section">
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="all"
                onSelect={e => handleSelect(e)}
              >
                <Container className="py-4 px-5 text-center">
                  <Row>
                    <Col xl={12} className="d-none d-xl-block">
                      <Nav className="justify-content-center">
                        <Nav.Item className="mx-2">
                          <Nav.Link eventKey="all">WSZYSTKIE</Nav.Link>
                        </Nav.Item>
                        {!loading &&
                          uniqueCategories &&
                          uniqueCategories.map((category, index) => (
                            <Nav.Item key={index} className="mx-2">
                              <Nav.Link eventKey={category}>
                                {category}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                      </Nav>
                    </Col>
                    <Col className="d-block d-xl-none col-auto mx-auto">
                      <NavDropdown title="Kategoria" id="nav-dropdown">
                        <NavDropdown.Item eventKey="all">
                          Wszystkie
                        </NavDropdown.Item>
                        {!loading &&
                          uniqueCategories &&
                          uniqueCategories.map((category, index) => (
                            <NavDropdown.Item key={index} eventKey={category}>
                              {category}
                            </NavDropdown.Item>
                          ))}
                      </NavDropdown>
                    </Col>
                  </Row>
                </Container>
                <Container className="mt-5" style={{ zIndex: 1 }}>
                  <InfiniteScroll
                    loadMore={loadMoreData}
                    hasMore={limitLoadMore <= LimitAll ? true : false}
                    loader={<div className="loader"></div>}
                  >
                    <Tab.Content className="mt-3">
                      <Tab.Pane eventKey="all">
                        <Row className="animated fadeInUp">
                          <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                          >
                            {loading && <span className="loader"></span>}
                            {error && (
                              <div className="text-dark">{`Wystąpił problem z wczytaniem aktualności - ${error}`}</div>
                            )}
                            {!loading &&
                              apiData &&
                              apiData.map((post, index) => (
                                <div
                                  className={`news-page-masonry-column ${
                                    index % 2 ? "masonry-two" : ""
                                  }`}
                                  key={index}
                                >
                                  <MatchesCard data={post} />
                                </div>
                              ))}
                          </Masonry>
                        </Row>
                      </Tab.Pane>
                      {!loading &&
                        uniqueCategories &&
                        uniqueCategories.map((category, index) => (
                          <>
                            <Tab.Pane eventKey={category} key={index}>
                              <Row className="animated fadeInUp">
                                <Masonry
                                  breakpointCols={breakpointColumnsObj}
                                  className="my-masonry-grid"
                                  columnClassName="my-masonry-grid_column"
                                >
                                  {loading && <span className="loader"></span>}
                                  {error && (
                                    <div className="text-dark">{`Wystąpił problem z wczytaniem aktualności - ${error}`}</div>
                                  )}
                                  {!loading &&
                                    selectedCategoryData &&
                                    selectedCategoryData.map((post, index) => (
                                      <div
                                        className={`news-page-masonry-column ${
                                          index % 2 ? "masonry-two" : ""
                                        }`}
                                        key={index}
                                      >
                                        <MatchesCard data={post} />
                                      </div>
                                    ))}
                                </Masonry>
                              </Row>
                            </Tab.Pane>
                          </>
                        ))}
                    </Tab.Content>
                  </InfiniteScroll>
                </Container>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NewsPage;
