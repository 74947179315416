import React from "react";
import { Link } from "gatsby";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/pl";

const MatchesCard = ({ data }) => {
  const {
    type,
    url,
    statusName,
    name,
    processedName,
    tags,
    categories,
    bannerImage,
    publishDate,
  } = data;

  const localPublishDate = moment.utc(publishDate).local();
  
  if (type == "sponsor") {
    return (
      <a href={url} target="_blank">
        <div className="singleTM card-container text-start card-sponsor-container">
          <div
            className="tm_img"
            style={{ backgroundImage: `url('${bannerImage}')` }}
          >
            <div className="tm_overlay"></div>
          </div>
        </div>
      </a>
    );
  } else {
    return (
      <Link to={`/${!type ? "jagatv" : "aktualnosci"}/${processedName}`}>
        <div className="singleTM card-container text-start">
          <div
            className="tm_img"
            style={{ backgroundImage: `url('${bannerImage}')` }}
          >
            <div className="tm_overlay"></div>
            <div className="detail_TM">
              {/* <div className="team_social">
                {tags
                  .toString()
                  .split(",")
                  .map((tag, index) => (
                    <a key={index} href="#">
                      <span>{tag}</span>
                    </a>
                  ))}
              </div> */}
              <h5>{name}</h5>
              <h6>
                <span className="text-white me-2">
                  <Moment date={localPublishDate} format="DD.MM.YYYY"></Moment>
                </span>
                <span>
                  <Moment date={localPublishDate} format="HH:mm"></Moment>
                </span>
              </h6>
            </div>
          </div>
        </div>
      </Link>
    );
  }
};

export default MatchesCard;
